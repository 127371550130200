@tailwind base;
@tailwind components;
@tailwind utilities;

#blocklyToolbox {
  display: none;
  position: absolute;
  top: 0px;
}

#toolbox-categories {
  display: none;
}

#inspectorContainer {
  display: none !important;
}

.toggle-edit {
  display: none !important;
}

.a-inspector-loader {
  display: none !important;
}

.a-enter-vr-button {
  display: none;
}

.loading-spinner {
  border-top-color: #00a4ef;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
